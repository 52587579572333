import Trix from "trix"
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginReact from '@bugsnag/plugin-react'

// Bugsnag.start({
//   apiKey: '38c9ebcd447e116481293666f1a3a147',
//   plugins: [new BugsnagPluginReact()],
//   enabledReleaseStages: ['production', 'staging', 'testing']
// })


document.addEventListener("trix-before-initialize", () => {

})

document.addEventListener("trix-initialize", () => {
  var trixOverrides = {
    bold: 'Fett',
    italic: 'Kursiv',
    strike: 'Durchgestrichen',

    'heading-1': 'Titel',
    quote: 'Zitat',
    code: 'Code',
    'bullet-list': 'Liste',
    'number-list': 'Aufzählung',
    'decrease-nesting-level': 'Einzug verkleinern',
    'increase-nesting-level': 'Einzug vergrössern',

    undo: 'Rückgängig',
    redo: 'Wiederherstellen'
  }

  $.each(trixOverrides, function (key, value) {
    $('.trix-button--icon-' + key).each(function () {
      var button = $(this)
      button.prop('title', value)
      button.text(value)
    })
  })

  $('trix-editor').each(function () {
    var element = $(this)
    var target = $('#' + element.attr('input'))
    var form = target.closest('form')
    var toolbarId = element.attr('toolbar')

    var toolbar = $('#' + toolbarId)
    toolbar.hide()
    element.css('max-height', '100px')
    element.css('overflow-y', 'scroll')

    element.on('focus', function () {
      toolbar.show()
      element.css('max-height', '80vh')
    })
    element.on('blur', function () {
      toolbar.hide()
      element.css('max-height', '100px')
    })

    if (form && form.hasClass('validate')) {
      element.on('trix-change', function () {
        console.log('trix change', element, target)
        // form.formValidation('revalidateField', target.attr('name'))

        $('form.validate').formValidation('destroy')
        $('form.validate').formValidation('validate');
      })
    }
  })
})



